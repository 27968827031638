import React from "react";
import urlJoin from "url-join";
import { storyblokEditable } from "@storyblok/react";

import Banner from "@components/molecules/Banner";
import RichText from "@components/atoms/RichText";
import { removeTrailingSlash } from "@utils/removeTrailingSlash";

import { BannerStoryblokProps } from "./types";

const BannerStoryblok: React.FC<BannerStoryblokProps> = ({
  blok
}) => {
  const {
    link: {
      cached_url: cachedUrl,
      url,
      rel,
      target,
      story: { full_slug: fullSlug = "" } = {}
    },
    linkLabel,
    titleBlock,
    textColor,
    backgroundColor,
    mobileAlignment,
    tabletAlignment,
    desktopAlignment
  } = blok;

  const newestUrl = fullSlug || cachedUrl;
  const href = url || urlJoin("/", removeTrailingSlash(newestUrl));

  const {
    text,
    mobileTextAlignment,
    tabletTextAlignment,
    desktopTextAlignment
  } = titleBlock[0] || {};

  return titleBlock?.[0]?.text ? (
    <Banner
      title={
        <RichText
          content={text}
          {...{
            mobileTextAlignment,
            tabletTextAlignment,
            desktopTextAlignment
          }}
        />
      }
      buttonProps={{
        href,
        rel,
        target,
        label: linkLabel
      }}
      textColor={textColor?.color}
      backgroundColor={backgroundColor?.color}
      {...{ mobileAlignment, tabletAlignment, desktopAlignment }}
      {...storyblokEditable(blok)}
    />
  ) : null;
};

export default BannerStoryblok;
