import { useIntl } from "react-intl";
import { useTheme } from "styled-components";

import * as Styled from "./styles";
import { BannerProps } from "./types";

const Banner: React.FC<BannerProps> = ({
  title,
  buttonProps: { label, href, rel, target },
  textColor,
  backgroundColor,
  mobileAlignment,
  tabletAlignment,
  desktopAlignment
}) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();

  const alignment = [
    mobileAlignment || "flex-start",
    tabletAlignment || "flex-start",
    desktopAlignment || "flex-start"
  ];

  const horizontalPaddings = [
    mobileAlignment === "center" ? "1rem" : "0",
    tabletAlignment === "center" ? "1rem" : "0",
    desktopAlignment === "center" ? "1.25rem" : "0"
  ];

  const isSetBackgroundColor = !!backgroundColor;

  const verticalPaddings = isSetBackgroundColor
    ? ["2rem", "", "5rem"]
    : "0";

  return (
    <Styled.Wrapper
      flexDirection="column"
      justifyContent="flex-start"
      alignItems={alignment}
      color={textColor || theme.colors.white}
      py={verticalPaddings}
      px={horizontalPaddings}
      {...{ backgroundColor }}
    >
      {typeof title === "string" ? (
        <Styled.Title>{title}</Styled.Title>
      ) : (
        title
      )}

      {href && (
        <Styled.Button {...{ href, rel, target }}>
          {label ||
            formatMessage({
              defaultMessage: "Visit website"
            })}
        </Styled.Button>
      )}
    </Styled.Wrapper>
  );
};

export default Banner;
