import styled from "styled-components";
import Typography from "@pagepro-monorepo/ui/lib/components/styles/Typography";
import { FlexBox } from "@pagepro-monorepo/ui/lib/components/styles/Grid";
import shouldForwardProp from "@pagepro-monorepo/ui/lib/utils/shouldForwardProp";
import { color, ColorProps } from "styled-system";

import ButtonLink from "@components/atoms/ButtonLink";

export const Wrapper = styled(FlexBox).withConfig({
  shouldForwardProp
})<ColorProps>`
  ${color};
`;

export const Title = styled(Typography).attrs({
  forwardedAs: "h2",
  variant: "heading4"
})``;

export const Button = styled(ButtonLink)`
  margin-top: 3rem;
`;
